.footer {
  background-color: black;
}

.call {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0.5rem 1.25rem;
  font-size: 1rem;
  background-color: #292929;
  color: #d1d1d1;
  border: none;
  cursor: pointer;
  overflow: hidden;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  border: solid 0.5px rgb(91, 91, 91);
  margin-top: 2rem;

  &:hover {
    background-color: #343434;
  }

  &::before {
    content: "";
  position: absolute;
  top: 0;
  left: -150%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 25%,
    rgba(255, 255, 255, 0) 75%
  );
  transition: 0.5s;
  animation: light-sweep 1.75s ease-in-out infinite;
  }

  &::before {
    left: 100%
  }
}