.skills {
  background-color: black;
}

.tech {
  position: relative;
  display: flex;
  align-items: center;
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #1d1d1d; 
  color: #d1d1d1; 
  border: none;
  cursor: pointer;
  overflow: hidden; 
  border-radius: 10px; 
  transition: background-color 0.3s, color 0.3s; 
  border: solid 0.5px #1d1d1d;
  
  &:hover {
    background-color: #343434; 
    border: solid 0.5px rgb(91, 91, 91);
  }

  & h3 {
    font-size: 1.2rem;
  }
}