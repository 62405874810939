* {
    margin: 0;
    padding: 0;
    font-family: 'Raleway', sans-serif;
}

@tailwind base;
@tailwind components;
@tailwind utilities;

