.projects {
  background-color: black;
}

.all-projects,
.source-code {
  position: relative;
  display: inline-flex;
  align-items: center;
  padding: 0.25rem 1.25rem;
  font-size: 1rem;
  background-color: #292929;
  color: #d1d1d1;
  border: none;
  cursor: pointer;
  overflow: hidden;
  border-radius: 5px;
  transition: background-color 0.3s, color 0.3s;
  border: solid 0.5px rgb(91, 91, 91);
}

.all-projects:hover,
.source-code:hover {
  background-color: #343434;
}

.all-projects::before,
.source-code::before {
  content: "";
  position: absolute;
  top: 0;
  left: -150%;
  width: 100%;
  height: 100%;
  background: linear-gradient(
    90deg,
    rgba(255, 255, 255, 0) 0%,
    rgba(255, 255, 255, 0.3) 25%,
    rgba(255, 255, 255, 0) 75%
  );
  transition: 0.5s;
  animation: light-sweep 1.75s ease-in-out infinite;
}

.all-projects::before,
.source-code::before {
  left: 100%;
}

.project {
  display: flex;
  align-items: center;
  padding: 0.75rem;
  font-size: 1rem;
  background-color: #1d1d1d;
  color: #d1d1d1;
  border: none;
  overflow: hidden;
  border-radius: 10px;
  transition: background-color 0.3s, color 0.3s;
  border: solid 0.5px #1d1d1d;

  & h3 {
    font-size: 2rem;
    font-weight: 600;
  }

  & img {
    border-radius: 0.75rem;
  }
}

.project-skills {
  display: flex;
  align-items: center;
  padding: 0.45rem 0.75rem;
  font-size: 1rem;
  background-color: #2d2d2d;
  color: #9a9a9a;
  overflow: hidden;
  border-radius: 7px;
  transition: background-color 0.3s, color 0.3s;
  border: solid 0.25px #4c4c4c;

  &:hover {
    color: #d1d1d1;
    background-color: #505050;
  }
}

@keyframes light-sweep {
  0% {
    left: -100%;
  }
  100% {
    left: 100%;
  }
}
